import '../../animations.css'
import '../../app.css'
import { useState } from 'react'
import moment from 'moment'
import { PageContainer, OptionsContainer, NextButton } from './StyledComponents'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import styled from 'styled-components'
import { scrollToBottom } from '../../utils/helpers'
import DateTime from '../../utils/DateTime'

function DateSelector({ data, setData, updatePages }) {

    const [date, setDate] = useState(DateTime.getDate)
    const maxCalenderDate = new Date(moment(DateTime.getDate()).add(29, 'days').format('YYYY/MM/DD'))

    const updateData = () => {
        setData({...data, date: {day: moment(date).format('DD'), month: moment(date).format('MM'), year: moment(date).format('yyyy')}})
        // setData({...data, date: date})
        updatePages(pages => [...pages, 4])
        scrollToBottom()
    }

    const handleDateChange = (e) => {
        setDate(e)
        // alert(date)
    }

    return (
        <PageContainer>
            <OptionsContainer width={550} fontSize='1.2em' >
                <div className='title'>Select a Date</div>
                    <CalendarContainer>
                        <Calendar 
                            className='calender'
                            calendarType='US'
                            defaultValue={date}
                            minDate={DateTime.getDate()}
                            maxDate={maxCalenderDate}
                            value={date}
                            onChange={handleDateChange}
                        />
                        <div className='date-note'>You can only select dates within the next 30 days.</div>
                    </CalendarContainer>
                    <NextButton onClick={updateData}>DONE</NextButton>
            </OptionsContainer>
        </PageContainer>
    )
}

const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px;

    .calender {
        border: none;
        border-radius: 15px;
        height: 100%;
        width: 100%;
        margin-bottom: 15px;
        background: var(--theme-bg);

        .react-calendar__tile {
            background: var(--theme-bg);
        }

        .react-calendar__tile {
            border-radius: 8px;
            color: #000000;
        }

        button:disabled {
            opacity: 0.5;
            background: #ffffff !important;
            border-radius: 0 !important;
        }

        .react-calendar__navigation__arrow[type=button] {
            background: var(--theme-bg) !important;
        }

        .react-calendar__tile[type=button]:disabled:nth-child(1) {
            border-top-left-radius: 10px !important;
        }  
        .react-calendar__tile[type=button]:disabled:last-child {
            border-bottom-right-radius: 10px !important;
        }  

        .react-calendar__navigation__prev-button,
        .react-calendar__navigation__prev2-button,
        .react-calendar__navigation__next-button,
        .react-calendar__navigation__next2-button {
            font-size:2em;
            font-weight: bold;
            background: var(--theme-bg);
        }

        .react-calendar__month-view__weekdays__weekday > abbr {
            text-decoration: none;
            font-size: 0.8rem;
        }

        .react-calendar__navigation__label {
            font-weight: 600;
            font-size: 1.2rem;
            border-radius: 10px;
        }

        .react-calendar__tile--active {
            transition: all 250ms;
            background: var(--theme);

            abbr {
                padding: 5px;
                border-radius: 50%;
                background: #ffffff;
                color: var(--theme);
                font-weight: bold;

                @media(max-width: 480px) {
                    padding: 2.5px;
                }
            }

            &:active {
                transform: scale(0.85);
            }

            &:focus {
                transform: scale(1.15);
            }
        }
    }

    .date-note {
        width: 80%;
        margin: 20px 0;
        text-align: center;
        font-size: 0.95rem;
    }
`
 
export default DateSelector