import styled from 'styled-components'
import '../../animations.css'
import '../../app.css'
import { useEffect, useState } from 'react'
import { PageContainer, NextButton } from './StyledComponents'
import moment from 'moment'
import uuid from 'react-uuid'
import { bookReservation, getUser } from '../../api/auth/handlers'
import { handleGetCustomer, handleGetPaymentCard } from '../../api/stripe/handlers'
import { getPaymentCardImage } from '../../utils/helpers'

function ReservationDetailsPage({ data, setData, updatePages, handleBooking, user }) {


    const { passengerName, passengerPhone, recordLocator, driverName, driverPhone, additionalNotes } = data.passengerDetails

    const [reservationNumber] = useState(uuid().split('-')[1]+(new Date().getMilliseconds())+uuid().split('-')[4])
    const [stripeCustomer, setStripeCustomer] = useState(null)
    const [defaultPaymentSourceId, setDefaultPaymentSourceId] = useState(null)
    const [defaultCard, setDefaultCard] = useState(null)

    const handleCompleteReservation = () => {
        bookReservation(user, data, reservationNumber)
        updatePages('thankYouPage')
    }

    useEffect(() => {
        getDefaultPaymentCard()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(stripeCustomer !== null && defaultPaymentSourceId !== null) {
            console.log(stripeCustomer)
            console.log(defaultPaymentSourceId)
            handleGetPaymentCard(stripeCustomer, defaultPaymentSourceId).then((res) => {
                setDefaultCard(res.data)
            })
        }
    }, [stripeCustomer, defaultPaymentSourceId])

    const getDefaultPaymentCard = () => {
        getUser(user)
        .then(userObject => {    
            setStripeCustomer(userObject.data()?.stripeId)        
            handleGetCustomer(userObject.data()?.stripeId)
            .then((res) => {
                setDefaultPaymentSourceId(res.data.default_source)
            })
        })
    }

    const serviceItems =
        [
            {
                label: 'Airport',
                value: data.airport,
            },
            {
                label: 'Service',
                value: data.service,
            },
            {
                label: 'Date',
                value: moment(data.date).format('ddd, MMMM Do YYYY'),
            },
            {
                label: 'Time',
                value: moment(data.date).format('hh:mm A'),
            },
            {
                label: 'Flight No',
                value: data.flightNumber.toUpperCase(),
            },
            {
                label: 'Passenger',
                value: passengerName,
            },
            {
                label: 'Passenger Phone',
                value: passengerPhone,
            },
            {
                label: 'PNR',
                value: recordLocator,
            },
            {
                label: 'Driver Name',
                value: driverName,
            },
            {
                label: 'Driver Phone',
                value: driverPhone,
            },
            {
                label: 'Notes',
                value: additionalNotes,
            },
        ]

        const chargeItems = 
        [
            {
                label: 'Departure Fee',
                value: ''
            },
            {
                label: 'Additional Hours',
                value: ''
            },
            {
                label: 'Early Morning/Late Night Surcharge',
                value: ''
            },
            {
                label: 'Total',
                value: ''
            },
        ]

    return (
        <PageContainer>
            <ReservationDetailsContainer>
                <div className='title'>Reservation Details</div>
                <div className="container">
                    <div className="reservation-number-row container-row">
                        <div className="label">
                            <span>Your reservation number is:</span>
                            <span className="reservation-number">{reservationNumber}</span>
                            {/* {copied ? <CheckIcon className="copied-icon" title="copy your reservation number"/> : <ContentCopyIcon className="copy-button" onClick={() => setCopied(true)}/>} */}
                        </div>
                    </div>
                    <div className="row-items service-items">
                        <div className="row-title">Service Items:</div>
                        {serviceItems.map(item => (
                            <div className="row-item service-item">
                                <div className="label">{item.label}:</div>
                                <div className="value">{item.value}</div>
                            </div>
                        ))}
                    </div>
                    <div className="row-items service-items">
                        <div className="row-title">Charge Items:</div>
                        {chargeItems.map(item => (
                            <div className="row-item charge-item">
                                <div className="label">{item.label}:</div>
                                <div className="value">{item.value}</div>
                            </div>
                        ))}
                    </div>

                    <div className="row-items credit-card-row">
                        <span className='row-title'>Payment Method:</span>
                        <span className='card-info'>
                            <span className='brand-icon'>{getPaymentCardImage(defaultCard?.brand)}</span>
                            <span className="number">****{defaultCard?.last4}</span>
                            <span className="expiry">{defaultCard?.exp_month}/{defaultCard?.exp_year?.toString().substr(-2)}</span>
                        </span>
                    </div>
                </div>
            <NextButton onClick={handleCompleteReservation}>Complete Reservation</NextButton>
            </ReservationDetailsContainer>
        </PageContainer>
    )
}

const ReservationDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 580px;
    height: 75vh;
    margin: 8px 0;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    animation: zoom-in 350ms;

    @media (max-width: 480px) {
        width: 95%;
    }
    
    & .title {
        position: relative;
        top: 0;
        background: var(--theme);
        text-align: center;
        padding: 8px 0;
        font-size: 1.6rem;
        width: 100%;
        font-weight: 600;
        color: white;
        border-radius: 15px 15px 0 0;
        user-select: none;
    }
    
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: scroll;
        margin: 15px 0;
        height: 100%;

        &::-webkit-scrollbar {
        display: flex;
        width: 5px;
        margin: 20px;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--theme-transparent);
            border-radius: 5px;
            
            &:hover {
                background: var(--theme)
            }
        }
        
        .container-row {
            width: 100%;
            justify-content: center;
            text-align: center;
            margin: 10px 0;
        }

        .reservation-number-row {
            .label {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            
                .reservation-number {
                    margin-left: 5px;
                    padding: 5px 10px;
                    color: #ffffff;
                    background: var(--theme);
                    font-size: 0.95rem;
                    font-weight: 600;
                    border-radius: 8px;
                }

                .copy-button,
                .copied-icon {
                    color: var(--theme-dark);
                    margin: 5px;
                    cursor: pointer;
                    animation: zoom-in 250ms;
                }

                @media (max-width: 480px) {
                    flex-direction: column;

                    span {
                        margin: 5px 0;
                    }
                }
            }
        }

        .row-title {
            font-size: 1.5rem;
            font-weight: 700;
            margin-bottom: 10px;
        }

        .row-items {
            display: flex;
            flex-direction: column;
            width: 80%;
            align-self: center;
            text-align: left;
            text-transform: capitalize;
            margin: 20px 0;

            .row-item {
                margin: 5px 0;
                width: 100%;
                display: flex;
                font-size: 1.05rem;

                @media(max-width: 480px) {
                    justify-content: space-between;
                    font-size: 1rem;
                }

                .label {
                    width: 45%;
                    font-weight: 600;
                    justify-content: center;
                    margin-bottom: 5px;
                }
                
                .value {
                    @media(max-width: 480px) {
                        text-align: right;
                    }
                }     
            }
        }

        .credit-card-row {
            margin-top: 25px;
            width: 80%;
            align-self: center;

            .card-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 65%;
                font-weight:  600;
                margin: 15px 0;

                .brand-icon {
                    height: 65px;
                    width: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #2398D410;
                    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
                    border-radius: 50%;

                    img {
                        width: 40px;
                        object-fit: contain;
                    }
                }

                .number,
                .expiry {
                    background: #2398D410;
                    padding: 5px 15px;
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
                }

                @media (max-width: 480px) {
                    flex-direction: column;
                    width: 100%;

                    .brand-icon,
                    .number,
                    .expiry {
                        margin: 8px 0;
                    }
                }
            }
        }

    }
`

export default ReservationDetailsPage