import AirportSelector from "./AirportSelector"
import DateSelector from "./DateSelector"
import ServiceSelector from "./ServiceSelector"
import CheckAvailability from "./CheckAvailability"
import DetailsPage from "./DetailsPage"
import ReservationDetailsPage from "./ReservationDetailsPage"
import ThankYouPage from "./ThankYouPage"
import NotAvailablePage from "./NotAvailablePage"

function ReservationPages({pages, data, setData, updatePages, handleBooking, availability, user}) {
    return (
        <>
            {pages.includes(1) && <AirportSelector data={data} setData={setData} updatePages={updatePages} />}
            {pages.includes(2) && <ServiceSelector data={data} setData={setData} updatePages={updatePages} />}
            {pages.includes(3) && <DateSelector data={data} setData={setData} updatePages={updatePages} />}
            {/* {pages.includes(4) && <TimeSelector data={data} setData={setData} updatePages={updatePages} />} */}
            {pages.includes(4) && <CheckAvailability data={data} setData={setData} updatePages={updatePages} />}
            {availability === true && 
                <>
                    {pages === 'detailsPage' && <DetailsPage data={data} setData={setData} updatePages={updatePages} />}
                    {pages === 'reservationDetailsPage' && <ReservationDetailsPage data={data} setData={setData} updatePages={updatePages} user={user} />}
                    {pages === 'thankYouPage' && <ThankYouPage data={data} setData={setData} />}
                </>
            }
            {availability === false &&
                <NotAvailablePage />
            }
        </>
    )
}

export default ReservationPages
