import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import { getAllUsers } from '../../api/auth/handlers'
import ReactLoading from 'react-loading'
import { Loader } from '../pages/StyledComponents'

const UsersModal = ({open, onHide}) => {

    const [users, setUsers] = useState([])

    useEffect(() => {
        getAllUsers().then(res => {
            setUsers(res)
        })
    }, [open])

    const hideModal = () => {
        onHide(!true)
    }

    return (
        open &&
            <Modal title='Users' close={hideModal}>
                <UsersModalContainer>
                    {users.length > 1 ? users.map(user => (
                        <div key={user.userInfo.uid} className="user">
                            <div className="username">{user.userInfo.email.replace('@specialservicesny.com', '')}</div>
                        </div>
                    )) :
                    <Loader>
                        <ReactLoading type='balls' color='#000000'/>
                    </Loader>
                    }
                </UsersModalContainer>
            </Modal>
    )
}

const UsersModalContainer = styled.div`
    width: 600px;
    height: 300px;

    .user {
        margin: 5px;
        /* width: 100%; */
        padding: 20px 25px;
        background: #fafafa;
        border: 1px solid var(--blue);
        border-radius: 5px;
        text-align: center;

        .username {
            font-weight: 600;
            font-size: 1.5rem;
        }
    }
`

export default UsersModal
