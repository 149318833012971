import axios from 'axios'
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth'
import { collection, getDocs, doc, setDoc, getDoc, arrayUnion, arrayRemove } from 'firebase/firestore'
import moment from 'moment'
import { auth, db } from '../../config/firebase'
import DateTime from '../../utils/DateTime'

export const handleUserLogin = async(user) => {
    const userInfo = {
        uid: user.uid,
        email: user.email,
        metadata: {...user.metadata}
    }
    await setDoc(doc(db, 'users', user.email), {
        userInfo: userInfo,
        lastSeen: DateTime.getDate().toString(),
    }, { merge: true })
}

export const handleUpdateUserBillingAddress = async(user, billingAddress) => {
    console.log(billingAddress);
    await setDoc(doc(db, 'users', user.email), {
        billingAddress: billingAddress,
    }, { merge: true })
}

export const createUser = async(email, password) => {
    return await axios.post(`${process.env.REACT_APP_AUTH_BACKEND}/createUser`, {
        email,
        password,
    })
}
export const updatePassword = async(uid, email, oldPassword, newPassword) => {

    const cred = EmailAuthProvider.credential(email, oldPassword)
    let data = null
    
    await reauthenticateWithCredential(auth.currentUser, cred)
    .then(async() => {
        // authenticated
        await axios.post(`${process.env.REACT_APP_AUTH_BACKEND}/updatePassword`, {
            uid,
            newPassword,
        }).then(res => {
            data = res
        })
    })
    .catch((error) => {
        // not authenticated
        console.log(error.message);
    });

    return data
}

export const handleAddUser = async(user, stripeId) => {
    await setDoc(doc(db, 'users', user.email), {
        paymentMethods: [],
        stripeId,
    }, { merge: true }).then(() => {
        handleUserLogin(user)
    })
}

export const bookReservation = async(user, data, reservationNumber) => {

    // prepare user info object
    const userInfo = {
        uid: user.uid,
        email: user.email,
    }

    // prepare booking object
    const bookingObject = {
        reservationNumber: reservationNumber,
        bookingInfo: data,
        userInfo: userInfo,
    }

    // booking types
    const arrivals = []
    const departures = []
    const connections = []

    // create the arrival / departure / connection booking object
    switch (data.service) {
        case 'arrival':
            arrivals[data.date] = bookingObject
            break;
        case 'departure':
            departures[data.date] = bookingObject
            break;
        case 'connection':
            connections[data.date] = bookingObject
            break;
        default:
            break;
    }
    
    // store booking in the database
    switch (data.service) {
        case 'arrival':
            await setDoc(doc(db, 'bookings', 'arrivals'), {
                ...arrivals,
            }, { merge: true }) 
            break;

        case 'departure':
            await setDoc(doc(db, 'bookings', 'departures'), {
                ...departures
            }, { merge: true }) 
            break;

        case 'connection':
            await setDoc(doc(db, 'bookings', 'connections'), {
                ...connections
            }, { merge: true }) 
            break;
    
        default:
            break;
    }
}

export const getAllBookings = (reservationsSetter, datesSetter) => {
    const bookingsCollection = getDocs(collection(db, "bookings"))
    let bookings = []
    
    bookingsCollection.then(res => {
        res.forEach(doc => {
            if(!bookings.includes(moment(doc.id).format('DD-MM-YYYY'))) {
                datesSetter(bookings)
                reservationsSetter(allBookings => [...allBookings, doc.data()])
                bookings.push(moment(doc.id).format('DD-MM-YYYY'))
            }
        })
    })
    
    return bookings
}

export const getAllUsers = async() => {
    const usersCollection = getDocs(collection(db, "users"))
    const users = []
    await usersCollection.then(res => {
        res.forEach(doc => {
            users.push(doc.data())
        })
    })

    return users
}

export const getUser = async(user) => {
    const getUserObject = doc(db, 'users', user.email)
    
    return await getDoc(getUserObject)
}

export const addPaymentMethodToUser = async(user, paymentMethodObject) => {
    const getUserObject = doc(db, 'users', user.email)

    return await setDoc(getUserObject, {
        paymentMethods: arrayUnion(paymentMethodObject)
    }, { merge: true })
}

export const deletePaymentMethodFromUser = async(user, paymentMethodObject) => {
    const getUserObject = doc(db, 'users', user.email)

    return await setDoc(getUserObject, {
        paymentMethods: arrayRemove(paymentMethodObject)
    }, { merge: true })
}

export const getBlockedDates = async() => {
    const blockedDates = getDocs(collection(db, "blocks"))
    const dates = []
    await blockedDates.then(res => {
        res.forEach(doc => {
            dates.push(doc.data())
        })
    })

    return dates
}

export const blockDates = async(dates) => {
    const getBlockObject = doc(db, 'blocks', 'blockedDates')

    return await setDoc(getBlockObject, {
        dates: arrayUnion(...dates)
    }, { merge: true })
}