import './app.css'
import styled from 'styled-components'
import Header from './components/Header';
import Pages from './components/Pages';
import { useEffect, useState } from 'react';
import { auth } from './config/firebase';
import { onAuthStateChanged } from '@firebase/auth';
import { handleUserLogin } from './api/auth/handlers';

function App() {

  const [user, setUser] = useState(null)

  const [currentPage, setCurrentPage] = useState(1)

  const [authenticated, setAuthenticated] = useState(null)

  useEffect(() => {
    // moment.tz.setDefault("America/New_York")
  }, [])

  const checkForAuthenticatedPages = (user) => {
    let pages
    if(user === 'admin') {
      pages = window.location.pathname !== '/admin'
    } else {
      pages = window.location.pathname !== '/payment'&& window.location.pathname !== '/settings' && window.location.pathname !== '/'
    }
    return pages
  }

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      if(user) {
        setUser(user)
        setAuthenticated(true)
        handleUserLogin(user)        
        // if user is admin
        if(user.email.split('@')[0] === 'admin' && checkForAuthenticatedPages('admin')) {
          window.location = '/admin'
        }

        // if user is not admin
        if(user.email.split('@')[0] !== 'admin' && checkForAuthenticatedPages('user')) {
          window.location = '/'
        }
      } else {
        setAuthenticated(false)
        if(window.location.pathname !== '/login') {
          window.location = '/login'
        }
      }
    })
  }, [])

  return (
    <AppContainer>
      <Header setCurrentPage={setCurrentPage} authenticated={authenticated} user={user}/>
      <Pages currentPage={currentPage} setCurrentPage={setCurrentPage} authenticated={authenticated} user={user} setUser={setUser} />
    </AppContainer>
  );
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--theme-bg);
`

export default App;