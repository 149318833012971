import styled from "styled-components"

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: red;
    width: 100%;
    color: #000000;
    background: #ffffff;
    overflow: hidden;
    margin: 20px 0;
    background: var(--theme-bg);
`

export const OptionsContainer = styled.div`
    opacity: 1;
    margin: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${props => props.width}px;
    height: ${props => props.height ? `${props.height}` : 'fit-content'};
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    overflow: hidden;
    animation: zoom-in 350ms;
    background: var(--theme-bg);

    @media (max-width: 480px) {
        width: 80%;
        height: fit-content;
    }

    & .title {
        position: relative;
        top: 0;
        background: var(--theme);
        text-align: center;
        padding: 8px 0;
        font-size: 1.6rem;
        width: 100%;
        font-weight: 600;
        color: white;
        user-select: none;
    }

    & .options {
        display: flex;
        width: 100%;
        height: 150px;
        padding: 0 45px;
        justify-content: space-${props => props.space};
        align-items: center;

        @media (max-width: 480px) {
            flex-direction: column;
            height: 250px;
            justify-content: space-evenly;
        }

        .option {
            padding: 8px 25px;
            font-weight: 600;
            background: var(--theme);
            color: #ffffff;
            border-radius: 8px;
            font-size: ${props => props.fontSize};
            cursor: pointer;
            --webkit-cursor: pointer;
            transition: all 250ms;
            user-select: none;

            @media (max-width: 480px) {
                width: 100%;
                text-align: center;
            }

            &:hover {
                transform: scale(1.2);
                background: var(--theme-dark);
            }

            &:active {
                transform: scale(0.8);
            }
        }
    }

    & .option-active {
        transform: scale(1.2);
        background: var(--theme-dark) !important;
    }

    & .single-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
`

export const GoBackButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    height: 70px;
    z-index: 999;
    background: var(--theme);
    color: white;
    border-radius:  0 30px 30px 0;
    animation: back-button-animation 250ms;
    animation-delay: 500ms;
    cursor: pointer;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    padding: 1.2rem;


    .back-button:hover {
        animation: back-arrow-hover infinite 1s;
    }

    @media (max-width: 480px) {
        margin: 0;
        top: 95%;
    }
`

export const NextButton = styled.div`
    display: flex;
    margin-bottom: 20px;
    width: fit-content;
    padding: 5px 15px;
    border-radius:  8px;
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: bold;
    background: var(--theme);
    user-select: none;
    cursor: pointer;
    transition: all 250ms;
    opacity: ${props => props.disabled ? '0.5' : '1'};
    pointer-events: ${props => props.disabled ? 'none' : 'all'};

    &:hover {
        background: var(--theme-dark);
    }
`

export const Loader = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
`

export const PageTitle = styled.div`
    color: var(--theme);
    font-size: 2rem;
    font-weight:600;
    padding: 8px 25px;
`