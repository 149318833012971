import { useState } from 'react'
import styled from 'styled-components'
import '../../animations.css'
import '../../app.css'
import { PageContainer, OptionsContainer, NextButton } from './StyledComponents'

function CheckAvailability({ data, setData, updatePages }) {

    const [flightNumber, setFlightNumber] = useState('')

    const updateData = () => {
        updatePages('detailsPage')
        setData({...data, flightNumber})
    }

    return (
        <PageContainer>
            <OptionsContainer width={550} fontSize='1.2em' tabIndex='1'>
                <FlightNumberContainer>
                    <div className="single-container">
                        <div className='title'>Enter Flight Number</div>
                        <div className='flight-number-box'>
                            <input type='' placeholder='eg Flight Number' value={flightNumber} onChange={e => setFlightNumber(e.target.value)} />
                        </div>
                    </div>
                </FlightNumberContainer>
                <NextButton onClick={updateData}>CHECK</NextButton>
            </OptionsContainer>
        </PageContainer>
    )
}

const FlightNumberContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .flight-number-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 130px;
        padding: 15px;
        border-radius:  0 0 15px 15px;
        border: none;
        background: var(--theme-bg);

        input {
            width: 95%;
            padding: 10px 15px;
            font-size: 1.5rem;
            border: none;
            outline: none;
            border-radius: 10px;
            background: #fafafa;
            animation: input-field infinite 250ms;
            animation-duration: 2s;


            &:focus {
                box-shadow: 0 0 0 2px var(--theme-transparent);
                animation: none;
            }
        }

        input::placeholder {
            color: #d3d3d3;
        }
    }
`

export default CheckAvailability