// globals
const language = "en-US";
const timeZone = { timeZone: "America/New_York" };
const dateTime = new Date().toLocaleString(language, timeZone)


const getDate = () => {
    return new Date(dateTime)
}

const date = () => {
    return new Date(dateTime).toLocaleDateString()
}

const time = () => {
    return new Date(dateTime).toLocaleTimeString()
}

const DateTime = {
    getDate,
    date,
    time,
}

export default DateTime