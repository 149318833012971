import '../../animations.css'
import '../../app.css'
import { PageContainer, OptionsContainer } from './StyledComponents'
import { scrollToBottom } from '../../utils/helpers'

function ServiceSelector({ data, setData, updatePages }) {

    const services = ['arrival', 'departure', 'connection']

    const resetServices = () => {
        services.forEach(service => {
            document.querySelector(`.${service}`).classList.remove('option-active')
        })
    }
    
    const updateData = (data) => {
        resetServices()
        document.querySelector(`.${data.service}`).classList.toggle('option-active')
        setData(data)
        updatePages(pages => [...pages, 3])
        scrollToBottom()
    }

    return (
        <PageContainer>
                <OptionsContainer space='between' width={550} fontSize='1.05em' >
                    <div className='title'>Select a Service</div>
                    <div className='options'>
                        <div className='option arrival' onClick={(e) => updateData({...data, service: 'arrival'})}>Arrival</div>
                        <div className='option departure' onClick={(e) => updateData({...data, service: 'departure'})}>Departure</div>
                        <div className='option connection' onClick={(e) => updateData({...data, service: 'connection'})}>Connection</div>
                    </div>
                </OptionsContainer>
        </PageContainer>
    )
}

export default ServiceSelector