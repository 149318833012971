import axios from 'axios'

export const handleCreateCustomer = async(username, email) => {
  return await axios.post('https://us-central1-special-services-ny.cloudfunctions.net/specialServicesNYPaymentGateway/createCustomer', {
    username,
    email,
  })
  .catch(err => console.log(err)) 
}

export const handleGetCustomer = async(customerId) => {
  return await axios.post('https://us-central1-special-services-ny.cloudfunctions.net/specialServicesNYPaymentGateway/getCustomer', {
    customerId,
  })
  .catch(err => console.log(err))
}


export const handleUpdateCustomer = async(customerId, data) => {
  return await axios.post('https://us-central1-special-services-ny.cloudfunctions.net/specialServicesNYPaymentGateway/updateCustomer', {
    customerId,
    data,
  })
  .catch(err => console.log(err))
}

export const handleChargePaymentCard = (customerId, cardId, amount, description) => {
  axios.post('https://us-central1-special-services-ny.cloudfunctions.net/specialServicesNYPaymentGateway/chargePaymentCard', {
    customerId,
    cardId,
    amount,
    description,
  })
  .then(function (response) {
    const { status } = response 
    console.log('STATUS', status);
    console.log(response.data);
  })
  .catch(err => console.log(err)) 
}

export const handleAddPaymentCard = async(customerId, cardNumber, expiry, cvc) => {
  return await axios.post(`${process.env.REACT_APP_AUTH_STRIPE_BACKEND}/addPaymentCard`, {
    customerId,
    cardNumber,
    expiry,
    cvc,
  })
  .catch(err => console.log(err))
}

export const handleGetPaymentCard = async(customerId, cardId) => {
  return await axios.post(`${process.env.REACT_APP_AUTH_STRIPE_BACKEND}/getPaymentCard`, {
      customerId,
      cardId,
    })
    .catch(err => console.log(err))
}

export const handleDeletePaymentCard = async(customerId, cardId) => {
  return await axios.post(`${process.env.REACT_APP_AUTH_STRIPE_BACKEND}/deletePaymentCard`, {
      customerId,
      cardId,
    })
    .catch(err => console.log(err))
}

export const handleChangeDefaultCard = (customerId, cardId) => {
    axios.post(`${process.env.REACT_APP_AUTH_STRIPE_BACKEND}/changeDefaultCard`, {
        customerId,
        cardId,
      })
      .then(function (response) {
        const { status } = response 
        console.log('STATUS', status);
        console.log(response.data);
      })
      .catch(err => console.log(err))
}

