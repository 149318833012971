import { useState, useEffect } from 'react'
import styled from 'styled-components'
import AddUserModal from '../modals/AddUserModal'
import Calendar from 'react-calendar'
import moment from 'moment'
import { getAllBookings } from '../../api/auth/handlers'
import UsersModal from '../modals/UsersModal'
import BlockDateTimeModal from '../modals/BlockDateTimeModal'
import { PageTitle } from './StyledComponents'

const AdminPanel = () => {

    const [addUserModal, setAddUserModal] = useState(false)
    const [usersModal, setUsersModal] = useState(false)
    const [blockDateTimeModal, setBlockDateTimeModal] = useState(false)
    const [date, setDate] = useState(new Date(moment().format('YYYY-MM-DD')))
    // eslint-disable-next-line
    const [reservations, setReservations] = useState([])
    const [dates, setDates] = useState([])

    useEffect(() => {
        getAllBookings(setReservations, setDates)
    }, [])

    const handleDateChange = (e) => {
        setDate(e)
        alert(e)
    }

    return (
        <AdminPanelContainer>
            <PageTitle>Admin Panel</PageTitle>
            <div className="admin-panel-content">
                <div className="admin-panel-calender">
                    <CalendarContainer>
                        <Calendar
                            className='calender'
                            calendarType='US'
                            defaultValue={date}
                            minDate={date}
                            maxDate={new Date(moment(moment().add(29, 'days').format('YYYY-MM-DD')))}
                            value={date}
                            onChange={handleDateChange}
                            tileClassName={({ date, view }) => {
                                if(dates.find(d => d === moment(date).add(1, 'day').format("DD-MM-YYYY"))){
                                    return 'highlight'
                                }
                            }} />
                    </CalendarContainer>
                </div>
                <div className="admin-panel-options">
                    <div className="add-user admin-option-button" onClick={() => setAddUserModal(true)}>
                        Add User
                    </div>
                    <div className="users admin-option-button" onClick={() => setUsersModal(true)}>
                        Users
                    </div>
                    <div className="block-dates admin-option-button" onClick={() => setBlockDateTimeModal(true)}>
                        Block Dates/Time
                    </div>
                    <div className="add-user admin-option-button">
                        Charge Amounts
                    </div>
                </div>
            </div>
            <AddUserModal open={addUserModal} onHide={setAddUserModal}/>
            <UsersModal open={usersModal} onHide={setUsersModal}/>
            <BlockDateTimeModal open={blockDateTimeModal} onHide={setBlockDateTimeModal}/>
        </AdminPanelContainer>
    )
}

const AdminPanelContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 15px;

    .admin-panel-content {
        display: flex;
        height: 100%;
        width: 100%;
        padding: 15px;
        margin-top: 25px;
        flex-wrap: wrap;
        flex-grow: .5;

        .admin-panel-calender {
            flex: 0.5;
            margin-right: 10px;
            display: grid;
            place-items: center;
            height: 500px;
            width: 100%;
            border-radius: 15px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            background: #fafafa;
        }
        
        .admin-panel-options {
            flex: 0.5;
            display: flex;
            margin-left: 10px;
            height: 500px;
            width: 100%;
            border-radius: 15px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
            background: #fafafa;
            flex-wrap: wrap;
            
            .admin-option-button {

                &:nth-child(1) {
                    flex-basis: 45%;
                }

                &:nth-child(2) {
                    flex-basis: 45%;
                }

                flex: 1;
                display: grid;
                place-items: center;
                margin: 5px;
                border-radius: 10px;
                padding: 15px;
                background: var(--theme);
                color: #ffffff;
                font-size: 1.5rem;
                font-weight: bold;
                text-align: center;
                user-select: none;
                cursor: pointer;
                transition: all 250ms;

                &:hover {
                    background: var(--theme-dark);
                }

                &:active {
                    transform: scale(0.85);
                }
            }
        }
    }
`

const CalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 5px;

    .calender {
        border: none;
        border-radius: 15px;
        height: 100%;
        width: 100%;
        padding: 10px;

        .highlight {
            transition: all 250ms;
            z-index: 999;
            position: relative;
            animation: zoom-in 250ms;
            
            abbr {
                padding: 5px;
                border-radius: 50%;
                font-size: 1.5rem;
                width: 100%;
                color: #ffffff;
                font-weight: bold;
                z-index: 99;
                
                @media(max-width: 480px) {
                    padding: 2.5px;
                }
            }
            
            abbr::before {
                position: absolute;
                content: ' ';
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 45px;
                height: 45px;
                background: var(--theme);
                border-radius: 50%;
                z-index: -99;
            }

            &:active {
                transform: scale(0.85);
            }

        }

        .react-calendar__tile {
            background: #ffffff;
            font-size: 1.2rem;

            &:hover {
                background: #fafafa;
            }
        }

        .react-calendar__tile {
            border-radius: 8px;
            color: #000000;
        }

        button:disabled {
            opacity: 0.5;
            background: #ffffff !important;
            border-radius: 0 !important;
        }

        .react-calendar__navigation__prev-button,
        .react-calendar__navigation__prev2-button,
        .react-calendar__navigation__next-button,
        .react-calendar__navigation__next2-button {
            font-size:2em;
            font-weight: bold;
        }

        .react-calendar__month-view__weekdays__weekday > abbr {
            text-decoration: none;
            font-size: 0.8rem;
        }

        .react-calendar__navigation__label {
            font-weight: 600;
            font-size: 1.2rem;
            border-radius: 10px;
        }

        /* .react-calendar__tile--active {
            transition: all 250ms;
            background: var(--theme);
            z-index: 999;

            &:hover {
                background: var(--theme-dark);
            }

            abbr {
                padding: 5px;
                border-radius: 50%;
                font-size: 1.8rem;
                color: #ffffff;
                font-weight: bold;

                @media(max-width: 480px) {
                    padding: 2.5px;
                }
            }

            &:active {
                transform: scale(0.85);
            }

            &:focus {
                transform: scale(1.15);
            }
        } */
    }
`

export default AdminPanel