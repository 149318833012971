import { useEffect, useState } from 'react'
import Routes from '../Routes'
import { getFlightStatus } from '../api/flightstats/handlers'
import { getBlockedDates } from '../api/auth/handlers'
import Loading from './elements/Loading'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../config/firebase'
import moment from 'moment'

const Pages = ({currentPage, setCurrentPage, authenticated, user, setUser}) => {
    const [data, setData] = useState(null)
    const [pages, updatePages] = useState([1])
    const [availability, setAvailability] = useState(null)
    const [loading, setLoading] = useState(false)
    const [allBookings, updateAllBookings] = useState([])
    const bookings = getDocs(collection(db, "bookings"))
    const [blockedDates, setBlockedDates] = useState([])

    useEffect(() => {
        bookings.then(res => {
            res.forEach(doc => {
                if(!allBookings.includes(doc.id)) {
                    updateAllBookings(allBookings => [...allBookings, doc.id])
                }
            })
        })
        handleFetchBlockedDates()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        console.log(blockedDates);
    }, [blockedDates])

    const handleFetchBlockedDates = () => [
        getBlockedDates().then(res => {
            setBlockedDates(res[0].dates)
        })
    ]

    useEffect(() => {
        console.log(data);
    }, [data])

    useEffect(() => {
        if(pages === 'detailsPage') {
            setLoading(true)
            
            fetchFlightData()
        }
        // eslint-disable-next-line
    }, [pages])

    const checkForBlockedDate = (inputDate) => {
        let blocked = false
        blockedDates.map(date => {
            const flightDate = moment(inputDate).format('YYYY-MM-DD').toString()
            const blockedDate = moment(date).format('YYYY-MM-DD').toString()

            if(flightDate === blockedDate) {
                blocked = true
            }

            return blocked
        })

        return blocked
    }

    const fetchFlightData = () => {
        getFlightStatus(data)
            .then(async(res) => {
                if(res.data.flightStatuses) {
                    console.log(res.data?.flightStatuses[0]);
                    const flightResponse = res.data?.flightStatuses[0]

                    console.log(!checkForBlockedDate(flightResponse?.departureDate?.dateLocal));

                    if(!checkForBlockedDate(flightResponse?.departureDate?.dateLocal)) {
                        // logic for  the availability
                        if(data.service === 'arrival' && !allBookings.includes(flightResponse?.arrivalDate?.dateLocal)) {
                            // if service type is arrival
                            const dateMatched = moment(flightResponse?.arrivalDate?.dateLocal).format('DD') === data.date.day
                            const monthMatched = moment(flightResponse?.arrivalDate?.dateLocal).format('MM') === (data.date.month)
                            const timeMatched = moment(flightResponse?.arrivalDate?.dateLocal)
                            const remainingTime = moment.duration(timeMatched.diff(moment())).asHours().toFixed(1)
                
                            // console.log(dateMatched);
                            // console.log(monthMatched);
                            // console.log(remainingTime >= 12);

                            if(dateMatched && monthMatched && remainingTime >= 12) {
                                setAvailability(true)
                                setLoading(false)
                                setData({...data, date: flightResponse?.arrivalDate?.dateLocal})
                            } else {
                                setAvailability(false)
                                setLoading(false)
                            }
                            
                        } else if (data.service === 'departure' && !allBookings.includes(flightResponse?.departureDate?.dateLocal)) {
                            // if service type is departure
                            const dateMatched = moment(flightResponse?.departureDate?.dateLocal).format('DD') === data.date.day
                            const monthMatched = moment(flightResponse?.departureDate?.dateLocal).format('MM') === (data.date.month)
                            const timeMatched = moment(flightResponse?.departureDate?.dateLocal)
                            const remainingTime = moment.duration(timeMatched.diff(moment())).asHours().toFixed(1)

                            // console.log(dateMatched);
                            // console.log(monthMatched);
                            // console.log(remainingTime >= 12);
                            
                            if(dateMatched && monthMatched && remainingTime >= 12) {
                                setAvailability(true)
                                setLoading(false)
                                setData({...data, date: flightResponse?.departureDate?.dateLocal})
                            } else {
                                setAvailability(false)
                                setLoading(false)
                            }
                            
                        } else {
                            setLoading(false)
                            setAvailability(false)
                        }
                    } else {
                        setLoading(false)
                        setAvailability(false)
                    }
            
                } else {
                    setLoading(false)
                    setAvailability(false)
                }
            }
         )
    }
    
    return (
        <>
            <Routes pages={pages} updatePages={updatePages} data={data} setData={setData} authenticated={authenticated} user={user} setUser={setUser} availability={availability} />
            {loading && <Loading title='Checking for availability'/>}
        </>
    )
}

export default Pages
