import React from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'

const Modal = (props) => {
    return (
        <ModalContainer>
            <ModalChild height={props.height}>
                <CloseModalButton onClick={props.close}><CloseIcon /></CloseModalButton>
                <div className="title">{props.title}</div>
                {props.children}
            </ModalChild>
        </ModalContainer>
    )
}

const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    z-index: 999;
    background: #00000080;
    `

const ModalChild = styled.div`
    position: relative;
    background: var(--theme-bg);
    border-radius: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    animation: modal 350ms;
    
    .title {
        width: 100%;
        text-align: center;
        color: var(--theme);
        font-size: 2rem;
        font-weight:600;
        padding: 8px 25px;
        margin: 10px;
    }
`

const CloseModalButton = styled.div`
    position: absolute;
    display: grid;
    place-items: center;
    top: -5px;
    right: -5px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #ffffff;
    color: var(--blue-dark);
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
`

export default Modal
