import styled from 'styled-components'
import { useEffect, useState } from 'react'
import '../../app.css'
import { DeleteRounded } from '@mui/icons-material'
import { handleDeletePaymentCard, handleGetCustomer, handleUpdateCustomer } from '../../api/stripe/handlers'
import AddPaymentMethodModal from '../modals/AddPaymentMethodModal'
import { Loader, PageTitle } from './StyledComponents'
import ReactLoading from 'react-loading'
import { deletePaymentMethodFromUser, getUser } from '../../api/auth/handlers'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Alert, Snackbar, Tooltip } from '@mui/material'
import { getPaymentCardImage } from '../../utils/helpers'

function PaymentPage(props) {

    const { user } = props

    const [loading, setLoading] = useState(false)

    const [addPaymentMethodModal, setAddPaymentMethodModal] = useState(false)
    const [defaultPaymentSource, setDefaultPaymentSource] = useState(null)
    const [cardAdded, setCardAdded] = useState(false)
    const [cardDeleted, setCardDeleted] = useState(false)
    const [defaultCardChanged, setDefaultCardChanged] = useState(false)

    // eslint-disable-next-line
    const [service, setService] = useState({
        name: 'LGA Arrival',
        price: 300,
    })

    const [paymentCards, setPaymentCards] = useState([])

    useEffect(() => {
        setLoading(true)
        getDefaultPaymentCard()
        getPaymentCards()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        console.log(paymentCards);
    }, [paymentCards])

    const getPaymentCards = () => {
        getUser(user)
        .then(userObject => {
            if(userObject.exists()) {
                setPaymentCards(userObject.data()?.paymentMethods)
                // console.log(userObject.data())
                setLoading(false)
            } else {
                // console.log('user does not exist')
                setLoading(false)
            }
        })
    }
    
    const handleDeleteCreditCard = (customerId, cardId, cardObject) => {
        setLoading(true)
        handleDeletePaymentCard(customerId, cardId)
        .then(function (response) {
            if(response) {
                const { status } = response 
                if(status && status === 200) {
                    deletePaymentMethodFromUser(user, cardObject)
                    .then(() => {
                        getDefaultPaymentCard()
                        getPaymentCards()
                        setLoading(false)
                    })
                    .finally(() => {
                        setTimeout(() => {
                            setCardDeleted(true)
                        }, 800)
                    })
                } else {
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        })
    }

    const getDefaultPaymentCard = () => {
        getUser(user)
        .then(userObject => {            
            handleGetCustomer(userObject.data()?.stripeId)
            .then((res) => {
                setDefaultPaymentSource(res.data.default_source)
            })
        })
    }

    const changeDefaultPaymentMethod = (cardId) => {
        setLoading(true)
        const customerData = { default_source: cardId } 
        getUser(user)
        .then(userObject => {            
            handleUpdateCustomer(userObject.data()?.stripeId, customerData)
            .then(res => {
                getDefaultPaymentCard()
                getPaymentCards()
                setLoading(false)
            })
            .finally(() => {
                setTimeout(() => {
                    setDefaultCardChanged(true)
                }, 800)
            })
        })
    }

    const Notification = ({open, onHide, severity, title}) => (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => onHide(false)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert elevation={5} variant='filled' severity={severity} sx={{ width: '100%' }}>
                {title}
            </Alert>
        </Snackbar>
    )

    return (
        <PaymentPageContainer>
                <PageTitle>Payment Methods</PageTitle>
                {!loading ?
                    <div className="payment-methods">
                        {paymentCards?.length >= 1 ? paymentCards.map(paymentCard => (
                            <div className="payment-method" key={paymentCard.id}>
                                {paymentCard.id === defaultPaymentSource &&
                                <Tooltip title='Default Payment Method'>
                                    <CheckCircleIcon className="default-label" />
                                </Tooltip>}
                                {paymentCard.id !== defaultPaymentSource &&
                                    <Tooltip title='Make Default'>
                                        <CheckCircleIcon className="default-label make-default-label" onClick={() => changeDefaultPaymentMethod(paymentCard.id)}/>
                                    </Tooltip>}
                                <span className='card-icon'>{getPaymentCardImage(paymentCard.brand)}</span>
                                <span className="card-number">****{paymentCard.last4}</span>
                                <span className="card-expiry">{paymentCard.exp_month}/{paymentCard.exp_year.toString().substr(-2)}</span>
                                <Tooltip title='Delete Card'>
                                    <DeleteRounded className='delete-card-button' onClick={() => handleDeleteCreditCard(paymentCard.customer, paymentCard.id, paymentCard)}/>
                                </Tooltip>
                            </div>
                        )) :
                        
                        <NoPaymentMethodText>
                            No payment methods available
                        </NoPaymentMethodText>}
                    </div> :

                    <Loader>
                        <ReactLoading type='balls' color='#000000'/>
                    </Loader>
                }
                <div className="add-payment-method-button" onClick={() => setAddPaymentMethodModal(true)}>
                    ADD PAYMENT METHOD
                </div>
                <AddPaymentMethodModal user={user} open={addPaymentMethodModal} onHide={setAddPaymentMethodModal} getPaymentCards={getPaymentCards} setCardAdded={setCardAdded}/>
                <Notification open={cardAdded} onHide={setCardAdded} severity='success' title='Card Added Successfully'/>
                <Notification open={cardDeleted} onHide={setCardDeleted} severity='error' title='Card Deleted Successfully'/>
                <Notification open={defaultCardChanged} onHide={setDefaultCardChanged} severity='success' title='Default Card Changed Successfully'/>
        </PaymentPageContainer>
    )
}

const PaymentPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50vh;
    width: 100%;
    padding: 15px;

    .payment-methods {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 25px 0;
        width: 100%;
        border-radius: 15px;

        .payment-method {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 380px;
            padding: 15px 25px;
            background: #FAFAFA20;
            border-radius: 25px;
            border: 1px solid #D3D3D350;
            margin-top: 15px;
            position: relative;

            &:first-of-type {
                margin: 0;
            }

            .default-label {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: -45px;
                width: fit-content;
                font-weight: 600;
                font-size: 1.8rem;
                cursor: pointer;
            }

            .make-default-label {
                opacity: 0.25;
                transition: all 250ms;

                &:hover {
                    opacity: 1;
                }
            }

            .card-icon {
                    height: 65px;
                    width: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #2398D410;
                    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
                    border-radius: 50%;

                    img {
                        width: 40px;
                        object-fit: contain;
                    }
                }

                .card-number,
                .card-expiry {
                    font-weight: 600;
                    height: fit-content;
                    background: #2398D410;
                    padding: 5px 15px;
                    border-radius: 5px;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
                }

                .delete-card-button {
                    color: #DC3545;
                    cursor: pointer;
                    transition: all 250ms;

                    &:hover {
                        color: #CE3D64;
                    }
                }
        }
    }

    .add-payment-method-button {
        padding: 5px 25px;
        background: var(--theme);
        color: #ffffff;
        font-weight: 600;
        font-size: 1.2rem;
        border-radius: 8px;
        cursor: pointer;
        user-select: none;
        transition: all 250ms;

        &:hover {
            background: var(--theme-dark);
            transform: scale(1.05);
        }
    }

    .add-card-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 550px;

        .card-number-input-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
        
        .card-number-input-field {
            border-radius: 15px;
            margin: 15px;
            display: flex;
            justify-content: space-between;
            font-size: 1.5rem;
            padding: 20px;
            width: 100%;
            border: 1px solid var(--theme);
            /* background: red; */

            #card-number {
                font-size: 0.85rem;
            }

            label {
                &:first-of-type {
                    width: 50%;
                }
            }
            
            input {
                padding: 5px;
                margin: 5px;
                border-color: var(--theme) !important;
                &:focus {
                    box-shadow: 0 1px 0 0 var(--theme);
                }
            }
        }

        .is-invalid {
            border-color: red !important;
        }

        .danger-text {
            margin-bottom: 15px;
        }
    }
`

const NoPaymentMethodText = styled.div`
    margin: 35px 0;
    padding: 10px 25px;
    background: #2398D410;
    border-radius: 15px;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--theme-dark);
    animation: zoom-in 250ms;
`

export default PaymentPage
