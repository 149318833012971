import styled from 'styled-components'
import '../../animations.css'
import { PageContainer } from './StyledComponents'

const ThankYouPage = () => {
    return (
        <PageContainer>
            <ThankYouPageContainer>
                <span className='container'>As always, I thank you for trusting us.<br /><b style={{color: 'var(--theme)'}}>A copy of your reservation has been emailed to you.</b></span>
            </ThankYouPageContainer>
        </PageContainer>
    )
}

const ThankYouPageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    width: 100%;

    .container {
        animation: zoom-in 250ms;
        padding: 25px;
        font-size: 1.2rem;
        width: 50%;
        text-align: center;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

        @media (max-width: 480px) {
            font-size: 1em;
            width: 90%;
        }
    }
`

export default ThankYouPage