import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import ReactLoading from 'react-loading'
import { handleUserLogin, updatePassword } from '../../api/auth/handlers'

const ChangePasswordModal = ({user, open, onHide}) => {

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [passwordChanged, setPasswordChanged] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleChangePassword = () => {
        if(oldPassword && newPassword && newPassword.length >= 6) {
            setLoading(true)
            updatePassword(user.uid, user.email, oldPassword, newPassword)
            .then((res) => {
                const user = res.data
                handleUserLogin(user)
                setPasswordChanged(true)
                setLoading(false)
                resetFields()
                window.location.reload()
            })
            .catch(error => {
                setLoading(false)
                alert('Old password is wrong')
            })
        } else if (newPassword && newPassword.length < 6) {
            alert('Password must contains 6 or more character')
        } else {
            alert('Enter required details')
        }
    }

    const resetFields = () => {
        setOldPassword('')
        setNewPassword('')
        setLoading(false)
    }

    const hideModal = () => {
        setPasswordChanged(false)
        onHide(!true)
    }

    return (
        open &&
            <Modal title='Change Password' close={hideModal}>
                <ChangePasswordModalContainer>
                    {!passwordChanged && !loading && 
                        <div className="change-password-form">
                            <div className="old-password-input input-field">
                                <input type='password' placeholder='Old Password' value={oldPassword} onChange={e => setOldPassword(e.target.value)} />
                            </div>
                            <div className="new-password-input input-field">
                                <input type='password' placeholder='New Password' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                            </div>
                            <UpdateButton onClick={handleChangePassword}>Update</UpdateButton>
                        </div>
                    }
                    {loading &&
                        <Loader>
                            <ReactLoading type='balls' color='#000000'/>
                        </Loader>
                    }
                    {passwordChanged &&
                        <div className="password-changed-container">
                            <div className="container-title">Password Changed Successfully</div>
                            <div className="button" onClick={hideModal}>Done</div>
                        </div>
                    }
                </ChangePasswordModalContainer>
            </Modal>
    )
}

const ChangePasswordModalContainer = styled.div`
    width: 450px;
    height: 100%;
    padding: 15px;
    
    .change-password-form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .input-field {
            margin: 5px;

            input {
                border: 1px solid var(--theme);
                outline-width: 0;
                width: 100%;
                font-size: 1.8rem;
                padding: 5px 15px;
                color: var(--theme);
                background: transparent;
                border-radius: 10px;
            }
        }
    }
    
    .password-changed-container {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    `

const Loader = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
    `

const UpdateButton = styled.div`
    background: var(--theme);
    padding: 8px 0;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    color: #fafafa;
    text-transform: uppercase;
    margin: 5px;
    user-select: none;
    cursor: pointer;
    `

export default ChangePasswordModal
