import ReactLoading from 'react-loading'
import styled from 'styled-components'

const Loading = ({title}) => {
    return (
        <LoadingContainer>
            <ReactLoading type='balls' color='#000000'/>
            <div className="title">{title}</div>
        </LoadingContainer>
    )
}

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    height: fit-content;
    width: fit-content;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .title {
        color: var(--blue);
        font-size: 1.5rem;
        font-weight: 600;

        @media (max-width: 480px) {
            font-size: 1.2rem;
        }
    }
`

export default Loading