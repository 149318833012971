import '../../animations.css'
import '../../app.css'
import styled from "styled-components"
import React, { useState } from "react"
import { PageContainer, OptionsContainer, NextButton } from './StyledComponents'
import moment from 'moment'

function DetailsPage({ data, setData, updatePages }) {

    const [passengers, setPassengers] = useState([1]);

    // details states
    const [passengerName, setPassengerName] = useState('')
    const [passengerPhone, setPassengerPhone] = useState('')
    const [recordLocator, setRecordLocator] = useState('')
    const [driverName, setDriverName] = useState('')
    const [driverPhone, setDriverPhone] = useState('')
    const [additionalNotes, setAdditionalNotes] = useState('')

    const updateData = () => {
        setData({...data, passengerDetails: { passengerName, passengerPhone, recordLocator, driverName, driverPhone, additionalNotes }})
        updatePages('reservationDetailsPage')
    }

    const addPassenger = () => {
        setPassengers(allPassengers => [...allPassengers, passengers.length+1])
    }

    return (
        <PageContainer>
            <OptionsContainer width={550} fontSize='1.2em' >
                <div className='title'>We have availability!</div>
                <FlightData>
                    <div className="airport">{data.airport}</div>
                    <div className="service">{data.service}</div>
                    <div className="flightDate">{moment(data.date).format('YYYY/MM/DD')}</div>
                    <div className="flightTime">{moment(data.date).format('hh:mm A')}</div>
                </FlightData>
                <DetailsContainer>
                    <div className="description">Please complete details below</div>
                    {passengers.map((passenger) => (
                        <div className="input-field passenger-name" key={passenger}>
                            <div className="field-label">Passenger {passengers.length > 1 && `${passenger} `}Name</div>
                            <div className="field-input">
                                <input type="text" value={passengerName} onChange={e => setPassengerName(e.target.value)} />
                                {passenger === passengers.length && passengers.length !== 20 &&
                                    <div className="add-passenger-button" onClick={addPassenger} title="add upto 20 passengers">+</div>
                                }
                            </div>
                        </div>             
                    ))}
                    <div className="input-field passenger-phone">
                        <div className="field-label">Passenger Phone</div>
                        <div className="field-input">
                            <input type="number" value={passengerPhone} onChange={e => setPassengerPhone(e.target.value)} />
                        </div>
                    </div>
                    <div className="input-field record-locator">
                        <div className="field-label">Record locator (PNR)</div>
                        <div className="field-input">
                            <input type="text" placeholder="optional" value={recordLocator} onChange={e => setRecordLocator(e.target.value)} />
                        </div>
                    </div>
                    <div className="input-field driver-name">
                        <div className="field-label">Driver Name</div>
                        <div className="field-input" value={driverName} onChange={e => setDriverName(e.target.value)} >
                            <input type="text" placeholder="optional"/>
                        </div>
                    </div>
                    <div className="input-field driver-phone">
                        <div className="field-label">Driver Phone</div>
                        <div className="field-input" value={driverPhone} onChange={e => setDriverPhone(e.target.value)} >
                            <input type="number" placeholder="optional"/>
                        </div>
                    </div>
                    <div className="input-field additional-notes">
                        <div className="field-label">Additional Notes</div>
                        <div className="field-input" value={additionalNotes} onChange={e => setAdditionalNotes(e.target.value)} >
                            <input type="text" placeholder="optional"/>
                        </div>
                    </div>
                </DetailsContainer>
                <NextButton onClick={updateData}>NEXT</NextButton>
            </OptionsContainer>
        </PageContainer>
    )
}

const DetailsContainer = styled.div`
    height: 100%;
    width: 98%;
    margin: 5px;
    max-height: 500px;
    overflow-y: scroll;
    margin-bottom: 15px;

    &::-webkit-scrollbar {
        display: flex;
        width: 5px;
        margin: 20px;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--theme-transparent);
        border-radius: 5px;

        &:hover {
            background: var(--theme)
        }
    }

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 15px 5px;
    }

    .description {
        margin: 20px;
        text-align: left;
        font-size: 1rem;
        font-weight: 500;
        color: #808080;
    }

    .input-field {
        width: 95%;
        margin: 8px 0;
        display: flex;
        font-size: 1.2rem;
        align-items: center;

        .field-label {
            width: 75%;
            text-align: left;
            margin-left: 20px;
            margin-right: 10px;
        }

        .field-input {
            display: flex;
            align-items: center;
            width: 100%;
            box-shadow: 0 0 0 1px #D3D3D3;
            border-radius: 5px;
            transition: all 250ms;
            overflow: hidden;

            input {
                border: none;
                outline-width: 0;
                padding: 5px 10px;
                font-size: 1.2rem;
                width: 100%;
            }

            &:focus {
                box-shadow: 0 0 0 3px var(--theme);
            }
        }

        @media (max-width: 480px) {
            flex-direction: column;

            .field-label {
                text-align: center;
                margin-bottom: 10px;
            }
        }
    }

    .passenger-name {
        .add-passenger-button {
            margin-right: 2px;
            padding: 0 8px;
            user-select: none;
            font-size: 1.5rem;
            background: var(--theme);
            border-radius: 5px;
            color: #ffffff;
            font-weight: 500;
            cursor: pointer;

            &:hover {
                background: #ffffff;
                box-shadow: inset 0 0 0 2px var(--theme);
                color: var(--theme);
            }

            &:active {
                background: var(--theme-dark);
                color: #ffffff;
                box-shadow: none;
            }
        }
    }
`

const FlightData = styled.div`
    display: flex;
    margin-top: 25px;
    font-weight: 600;
    padding: 8px 15px;
    border-radius: 5px;
    background: #00000015;
    text-transform: capitalize;
    
    .airport, .service, .flightDate, .flightTime {
        margin: 0 5px;
    }
`

export default DetailsPage