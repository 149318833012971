import React, { useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded' 
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { createUser, handleAddUser } from '../../api/auth/handlers'
import ReactLoading from 'react-loading'
import { handleCreateCustomer } from '../../api/stripe/handlers'

const AddUserModal = ({open, onHide}) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatPassword, setShowRepeatPassword] = useState(false)
    const [userCreated, setUserCreated] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleShowPassword = (type) => {
        if(type === 'password') {
            if(!showPassword) {
                setShowPassword(true)
                document.querySelector('.input-password').type = 'text'
            } else {
                setShowPassword(false)
                document.querySelector('.input-password').type = 'password'
            }
        } else {
            if(!showRepeatPassword) {
                setShowRepeatPassword(true)
                document.querySelector('.input-repeat-password').type = 'text'
            } else {
                setShowRepeatPassword(false)
                document.querySelector('.input-repeat-password').type = 'password'
            }
        }
    }

    const resetFields = () => {
        setUsername('')
        setPassword('')
        setShowPassword(false)
    }

    const handleCreateUser = () => {
        const email = `${username}@specialservicesny.com`
        if(username && password.length >= 6 && password === repeatPassword) {
            setLoading(true)
            createUser(email, password)
            .then((res) => {
                const user = res.data
                handleCreateCustomer(username, email).then(res => {
                    const stripeCustomerId = res.data.id
                    handleAddUser(user, stripeCustomerId)
                    setUserCreated(true)
                    setLoading(false)
                    resetFields()
                })
            })
        } else if (password && password.length < 6) {
            alert('Password must contains 6 or more character')
        } else if (password !== repeatPassword) {
            alert('Password does not match')
        } else {
            alert('Enter required details')
        }
    }

    const hideModal = () => {
        setUserCreated(false)
        onHide(!true)
    }

    return (
        open &&
            <Modal title='Add User' close={hideModal}>
                <AddUserModalContainer>
                    {!userCreated && !loading && 
                        <div className="create-user-form">
                            <div className="user-name-input">
                                <PersonRoundedIcon className='field-icon'/>
                                <input type="text" placeholder='username' value={username} onChange={e => setUsername(e.target.value)} />
                            </div>
                            <div className="password-input">
                                <LockRoundedIcon className='field-icon'/>
                                <input type="password" placeholder='password' className='input-password' value={password} onChange={e => setPassword(e.target.value)}/>
                                {showPassword ? 
                                <VisibilityOffRoundedIcon className='show-pass-icon' onClick={() => handleShowPassword('password')}/>
                                : 
                                <VisibilityRoundedIcon className='show-pass-icon' onClick={() => handleShowPassword('password')}/>}
                            </div>
                            <div className="password-input">
                                <LockRoundedIcon className='field-icon'/>
                                <input type="password" placeholder='repeat password' className='input-repeat-password input-password' value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)}/>
                                {showRepeatPassword ? 
                                <VisibilityOffRoundedIcon className='show-pass-icon' onClick={() => handleShowPassword('repeatPassword')}/>
                                : 
                                <VisibilityRoundedIcon className='show-pass-icon' onClick={() => handleShowPassword('repeatPassword')}/>}
                            </div>
                            <div className="add-user-button button" onClick={handleCreateUser}>Add User</div>
                        </div>
                    }
                    {loading &&
                        <Loader>
                            <ReactLoading type='balls' color='#000000'/>
                        </Loader>
                    }
                    {userCreated &&
                        <div className="user-created-container">
                            <div className="container-title">User Created Successfully</div>
                            <div className="button" onClick={hideModal}>Done</div>
                        </div>
                    }
                </AddUserModalContainer>
            </Modal>
    )
}

const AddUserModalContainer = styled.div`
    width: 600px;
    height: 300px;

    .create-user-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    .user-name-input,
        .password-input {
            display: flex;
            align-items: center;
            width: 80%;
            background: #ffffff;
            padding: 5px 15px;
            border-radius: 15px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

            @media (max-width: 480px) {
                width: 100%;
            }

            .field-icon {
                font-size: 2rem;
                color: var(--theme);
            }
        }

        .password-input {
            margin-top: 15px;
            
            .show-pass-icon {
                color: #808080;
                cursor: pointer;
                transition: all 250ms;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .button {
            margin-top: 25px;
            padding: 5px 35px;
            background: var(--theme);
            color: #ffffff;
            font-size: 1.5rem;
            font-weight: 600;
            border-radius: 8px;
            user-select: none;
            cursor: pointer;
            transition: all 250ms;
            width: fit-content;

            &:hover {
                background: var(--theme-dark);
            }
        }

        input {
            width: 100%;
            font-size: 1.5rem;
            padding: 10px;
            border: none;
            outline-width: 0;

            @media (max-width: 480px) {
                font-size: 1.2rem;
            }
        }

        .user-created-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .container-title {
                font-size: 1.8rem;
                font-weight: 600;
                color: var(--theme);
            }
        }
`

const Loader = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
`

export default AddUserModal
