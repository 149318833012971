import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import ReactLoading from 'react-loading'
import moment from 'moment'
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { enUS } from 'date-fns/locale'
import DateTime from '../../utils/DateTime'
import { blockDates } from '../../api/auth/handlers'

const BlockDateTimeModal = ({open, onHide}) => {

    // const [time, setTime] = useState('')
    // eslint-disable-next-line
    const [datePicker, setDatePicker] = useState(true)
    // eslint-disable-next-line
    const [timePicker, setPicker] = useState(false)
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    const [dates, setDates] = useState([
        {
          startDate: DateTime.getDate(),
          endDate: DateTime.getDate(),
          key: 'selection'
        }
      ]);
    const [startDate, setStartDate] = useState(dates[0].startDate)
    const [endDate, setEndDate] = useState(dates[0].endDate)

    const hideModal = () => {
        onHide(!true)
    }

    const handleUpdateDates = (item) => {
        setStartDate(item[0].startDate)
        setEndDate(item[0].endDate)
        setDates(item)
    }

    useEffect(() => {
        console.log(getDaysArray(startDate, endDate));
        // eslint-disable-next-line
    }, [dates])

    var getDaysArray = function(start, end) {
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
            // push to dates array as EST DATE
            arr.push(moment(new Date(dt)).format('YYYY-MM-DD'));
        }
        return arr;
    };

    const handleBlockDates = () => {
        blockDates(getDaysArray(startDate, endDate))
        .then(() => {
            hideModal()
        })
    }

    return (
        open &&
            <Modal title='Block Dates/Times' close={hideModal}>
                <BlockDateTimeModalContainer>
                    {datePicker &&
                        <div className="date-picker">
                            <CalendarContainer>
                                <DateRange
                                    className='datePicker'
                                    fixedHeight
                                    minDate={DateTime.getDate()}
                                    locale={enUS}
                                    rangeColors= {['#000000']}
                                    color= '#000000'
                                    editableDateInputs={true}
                                    onChange={item => handleUpdateDates([item.selection])}
                                    moveRangeOnFirstSelection={false}
                                    ranges={dates}
                                />
                                <BlockButton onClick={handleBlockDates}>Block Date</BlockButton>
                            </CalendarContainer>
                        </div>
                    }
                    {timePicker &&
                        <div className="date-picker">
                            <h1>Date Picker</h1>
                        </div>
                    }
                    {loading &&
                        <Loader>
                            <ReactLoading type='balls' color='#000000'/>
                        </Loader>
                    }
                </BlockDateTimeModalContainer>
            </Modal>
    )
}

const BlockDateTimeModalContainer = styled.div`
    width: 600px;
    height: 500px;
    overflow: hidden;

    .button {
        margin-top: 25px;
        padding: 5px 35px;
        background: var(--theme);
        color: #ffffff;
        font-size: 1.5rem;
        font-weight: 600;
        border-radius: 8px;
        user-select: none;
        cursor: pointer;
        transition: all 250ms;
        width: fit-content;

        &:hover {
            background: var(--theme-dark);
        }
    }
`

const BlockButton = styled.div`
    margin-top: 15px;
    width: fit-content;
    padding: 5px 15px;
    font-size: 1.5rem;
    color: #fafafa;
    background: var(--theme);
    user-select: none;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

const CalendarContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .datePicker {
        align-self: center;
        width: fit-content;
        width: 95%;
        border-radius: 8px;
        overflow: hidden;
        background: var(--theme-bg);

        .rdrDayToday {
            span {
                font-weight: 800;
            }
        }

        .rdrDayNumber {
            span::after{
                background: none !important;
            }
        }

        .rdrDayPassive {
            .rdrDayNumber {
                span {
                    color: #00000025;
                }
            }
        }

        .rdrMonthPicker, .rdrYearPicker {
            select {
                font-weight: 800;
                font-size: 0.95rem;
            }
        }

        .rdrMonth{
            width: 100%;
        }

        .rdrDateInput, .rdrDateDisplayItem {
            font-weight: 600;
        }

        .rdrWeekDay {
            font-weight: 600;
        }
        
        .rdrDayNumber {
            font-size: 1.15rem;;
            font-weight: 800 !important;
        }
    }
`

const Loader = styled.div`
    display: grid;
    place-items: center;
    height: 100%;
    width: 100%;
`

export default BlockDateTimeModal