import styled from 'styled-components'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import LockRoundedIcon from '@mui/icons-material/LockRounded'
import { NextButton } from './StyledComponents'
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded' 
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { useState } from 'react'
import { signInWithEmailAndPassword } from '@firebase/auth'
import { auth } from '../../config/firebase'
import { handleUserLogin } from '../../api/auth/handlers'

function LoginPage({setUser}) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const handleLogin = () => {
        const email = `${username}@specialservicesny.com`
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            setUser(user)
            handleUserLogin(user)
        })
        .catch((error) => {
            console.log(error.message);
            alert('Incorrect email or password')
        })
    }

    const handleShowPassword = () => {
        if(!showPassword) {
            setShowPassword(true)
            document.querySelector('.input-password').type = 'text'
        } else {
            setShowPassword(false)
            document.querySelector('.input-password').type = 'password'
        }
    }

    return (
        <LoginPageContainer className='login-page-container'>
            <div className="title">Client Login</div>
            <div className="login-form">
                <div className="user-name-input">
                    <PersonRoundedIcon className='field-icon'/>
                    <input type="text" placeholder='username' value={username} onChange={e => setUsername(e.target.value)} />
                </div>
                <div className="password-input">
                    <LockRoundedIcon className='field-icon'/>
                    <input type="password" placeholder='password' className='input-password' value={password} onChange={e => setPassword(e.target.value)}/>
                    {showPassword ? 
                    <VisibilityOffRoundedIcon className='show-pass-icon' onClick={handleShowPassword}/>
                     : 
                     <VisibilityRoundedIcon className='show-pass-icon' onClick={handleShowPassword}/>}
                </div>
                <NextButton className='login-button' onClick={handleLogin}>LOGIN</NextButton>
            </div>
        </LoginPageContainer>
    )
}

const LoginPageContainer = styled.div`
    justify-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
    width: 550px;
    padding: 15px;

    @media (max-width: 480px) {
        width: 100%;
    }

    .title {
        color: var(--theme);
        font-size: 2rem;
        font-weight:600;
        padding: 8px 25px;
    }

    .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 35px 15px;
        border-radius: 15px;

        .user-name-input,
        .password-input {
            display: flex;
            align-items: center;
            width: 80%;
            background: #ffffff;
            padding: 5px 15px;
            border-radius: 15px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

            @media (max-width: 480px) {
                width: 100%;
            }

            .field-icon {
                font-size: 2rem;
                color: var(--theme);
            }
        }

        .password-input {
            margin-top: 15px;
            
            .show-pass-icon {
                color: #808080;
                cursor: pointer;
                transition: all 250ms;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        input {
            width: 100%;
            font-size: 1.5rem;
            padding: 10px;
            border: none;
            outline-width: 0;

            @media (max-width: 480px) {
                font-size: 1.2rem;
            }
        }

        .login-button {
            margin-top: 25px;
        }
    }
`

export default LoginPage
