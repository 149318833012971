import { PaymentCardImages } from "../components/elements/PaymentCardImages";

export const scrollToBottom = () => {
    setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, 0)
}

export const getPaymentCardImage = (brand) => {
    switch(brand) {
        case 'Visa':
            return <img src={PaymentCardImages.visa} alt="card-icon" />
        case 'MasterCard':
            return <img src={PaymentCardImages.mastercard} alt="card-icon" />
        case 'American Express':
            return <img src={PaymentCardImages.amex} alt="card-icon" />
        default:
            return <img src={PaymentCardImages.placeholder} alt="card-icon" />

    }
}